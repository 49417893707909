// eslint-disable-next-line no-unused-vars
import React, { useState, } from 'react'
import { Link as RouterLink, } from 'react-router-dom'
import { connect, } from 'react-redux'
import { toast, } from 'react-toastify'
import styles from './loginAndForgotPage.module.css'
import globalStyles from '../../assets/global.module.css'
import FormContentContainer from '../../containers/form/FormContentContainer'
import { getUserLoadingSelector, } from '../../store/selectors/userSelector'
import { userAuthAction, } from '../../store/actions/userActions'
import {
	ICON_LOGIN_NAME,
	ICON_LOGIN_PASSWORD,
	// ICON_LOGIN_PHONE,
} from '../../constants/iconConstants'
import {
	// newPhoneValidation,
	passwordValidation, phoneValidation,
	validationByError,
} from '../../utilits/validateUtilit'
import { getFormatUserDateUtil, } from '../../utilits/getUserUtilit'
import { getDefaultDataUtil, } from '../../utilits/getMainDataUtilit'
import { getMessageError, } from '../../utilits/getMessageTextUtilit'

const LOGIN_FIELDS = [
	// {
	// 	id: 'login',
	// 	value: '',
	// 	placeholder: 'Телефон',
	// 	className: styles.input,
	// 	iconName: ICON_LOGIN_PHONE,
	// eslint-disable-next-line max-len
	// 	validator: validationByError(newPhoneValidation('+7 (999) 999-99-99',), 'Указан не верный логин. Проверьте правльность ввода',),
	// 	type: 'mask',
	// 	mask: '+7 (999) 999-99-99',
	// 	error: '',
	// },
	{
		id: 'login',
		value: '',
		placeholder: 'Login',
		className: styles.input,
		iconName: ICON_LOGIN_NAME,
		validator: validationByError(phoneValidation, 'Указан не верный логин. Проверьте правльность ввода',),
		type: 'text',
		error: '',
	},
	{
		id: 'password',
		value: '',
		placeholder: 'Пароль',
		className: styles.input,
		iconName: ICON_LOGIN_PASSWORD,
		validator: validationByError(passwordValidation, 'Указан не верный пароль. Проверьте правльность ввода',),
		type: 'password',
		error: '',
	},
]

const LoginPageComponent = ({ onAuth, loading, },) => {
	const [state, updateState, ] = useState({
		item: {},
		errors: {},
	},)
	const onLogin = async (variables,) => {
		try {
			await onAuth(variables,)
			updateState({
				item: getFormatUserDateUtil(variables,),
				errors: {},
			},)
		} catch (e) {
			if (e.code === 401 && e.message === 'Invalid login credentials. Please try again') {
				updateState({
					item: getFormatUserDateUtil(variables,),
					errors: {
						login: 'Указан не верный логин. Проверьте правльность ввода',
						password: 'Указан не верный пароль. Проверьте правльность ввода',
					},
				},)
			} else if (e.code === 401) {
				toast.error(getMessageError(e.message || '',), {
					position: toast.POSITION.TOP_RIGHT,
				},)
			} else {
				toast.error(getMessageError(e.message || '',), {
					position: toast.POSITION.TOP_RIGHT,
				},)
			}
		}
	}
	return (
		<main className={styles.login}>
			<div className={globalStyles.container}>
				<div className={styles.content}>
					<div className={styles.title}>Парковка ГК ВДНХ</div>
					<FormContentContainer
						loading={loading}
						content={getDefaultDataUtil(state.item, state.errors,)(LOGIN_FIELDS,)}
						onSubmit={onLogin}
						wrapperClassName={styles.form}
						buttonWrapperClassName={styles.buttonWrapper}
						buttonClassName={styles.btn}
						buttonText="Войти"
					>
						<div className={styles.link}>
							<RouterLink to="/auth/forgot">Забыли пароль?</RouterLink>
						</div>
					</FormContentContainer>
				</div>
			</div>
		</main>
	)
}
export default connect(
	(state,) => ({
		loading: getUserLoadingSelector(state,),
	}),
	(dispatch,) => ({
		onAuth: (variables,) => dispatch(userAuthAction(variables,),),
	}),
)(LoginPageComponent,)
