// eslint-disable-next-line no-unused-vars
import React, { useState, } from 'react'
import { connect, } from 'react-redux'
import { Link as RouterLink, } from 'react-router-dom'
import { toast, } from 'react-toastify'
import styles from './loginAndForgotPage.module.css'
import globalStyles from '../../assets/global.module.css'
import {
	ICON_LOGIN_NAME,
} from '../../constants/iconConstants'
import {
	phoneValidation,
	validationByError,
} from '../../utilits/validateUtilit'
import FormContentContainer from '../../containers/form/FormContentContainer'
import { getUserLoadingSelector, } from '../../store/selectors/userSelector'
import { userRecoveryAction, } from '../../store/actions/userActions'
import TextModalComponent from '../../components/modal/TextModalComponent'
import { getFormatUserDateUtil, } from '../../utilits/getUserUtilit'
import { getDefaultDataUtil, } from '../../utilits/getMainDataUtilit'
import { getMessageError, } from '../../utilits/getMessageTextUtilit'

const FORGOT_FIELDS = [
	{
		id: 'login',
		value: '',
		placeholder: 'Login',
		className: styles.input,
		iconName: ICON_LOGIN_NAME,
		validator: validationByError(phoneValidation, 'Указан не верный логин. Проверьте правльность ввода',),
		type: 'text',
		error: '',
	},
]
const ForgotPageComponent = ({ loading, history, onRecovery, },) => {
	const [currentManager, updateManager, ] = useState('',)
	const [state, updateState, ] = useState({
		item: {},
		errors: {},
	},)
	const onAuth = async (variables,) => {
		try {
			const manager = await onRecovery(variables,)
			updateManager(
				manager.phone
					? `Обратитесь к вашему менеджеру ${manager.firstName} ${manager.middleName} ${manager.lastName} ${manager.phone}`
					: `Обратитесь к администратору личного кабинета ${manager.firstName} ${manager.middleName} ${manager.lastName}`,
			)

			updateState({
				item: getFormatUserDateUtil(variables,),
				errors: {},
			},)
		} catch (e) {
			if (e.message === 'Invalid request') {
				updateState({
					item: getFormatUserDateUtil(variables,),
					errors: { login: 'Указан не верный логин. Проверьте правльность ввода', },
				},)
			} else {
				toast.error(getMessageError(e.message,), {
					position: toast.POSITION.TOP_RIGHT,
				},)
			}
		}
	}

	return (
		<main className={styles.login}>
			<TextModalComponent
				isOpen={!!currentManager}
				onClose={() => updateManager('',)}
				description={currentManager}
				textButton="OK"
				onClick={() => history.push('/auth/login',)}
			/>
			<div className={globalStyles.container}>
				<div className={styles.content}>
					<div className={styles.title}>Парковка ГК ВДНХ</div>
					<form className={styles.form} action="#" data-app="login">
						<div className={styles.loginLabel}>Восстановление пароля</div>
						<FormContentContainer
							loading={loading}
							content={getDefaultDataUtil(state.item, state.errors,)(FORGOT_FIELDS,)}
							onSubmit={onAuth}
							wrapperClassName={styles.form}
							buttonWrapperClassName={styles.buttonWrapper}
							buttonClassName={styles.btn}
							buttonText="Далее"
						>
							<div className={styles.link}>
								<RouterLink to="/auth/login">Авторизация</RouterLink>
							</div>
						</FormContentContainer>
					</form>
				</div>
			</div>
		</main>
	)
}
export default connect(
	(state,) => ({
		loading: getUserLoadingSelector(state,),
	}),
	(dispatch,) => ({
		onRecovery: (variables,) => dispatch(userRecoveryAction(variables,),),
	}),
)(ForgotPageComponent,)
